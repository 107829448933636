.header{
    min-width: 500px;
    min-height: 10vh;
    background-color: white;
    display: flex;
    align-items:center;
    box-shadow: 0 2px 4px rgb(81 107 152 / 16%);
}
.innerContainer{
    align-items:center;
}
.innerContainer2{
    width: 400px;
    align-items: center;
}
.header__container{
    display: flex;
    align-items: center;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    opacity: initial;
    background-color: #FFFFFF;
}
img{
    width: 200px;
}

.ic{
    font-size: 30px;
    color: #FF8500;
    background: none;
    border: none;
    cursor: pointer;
}
.ic:focus{
    outline: 0;
}
.innerContainer2  >  a{
    color:#3B455A ;
    text-decoration: none;
    font-size: 15px;
    margin-right: 20px;
    font-family:Lato,sans-serif;
    font-weight: bold;
}
.innerContainer2  >  a:hover{
    color:#FF8500 ;
}
.btn_header{
    width:200px ;
    height: 41px;
    background-color: #516CF0;
    color: white;
    border: none;
    border-radius:4px ;
    font-weight: 400px;
    text-align: center;
    border: 1px solid transparent;
    font-size: 1rem;
}
.btn_header:hover{
    background-color: #FF8500;
}
.btn_header:active{
    border:3px solid #516cf0;
}

